import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import  { environment } from '../../environments/environment'
const httpOptions = {
  headers: new HttpHeaders({
   'Authorization':'Token ' + environment.api_key
  })
};
@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private http:HttpClient
  ) { }

  submitBooking(booking:any){
    return this.http.post(environment.bookingApi,booking,httpOptions);
  }

  submitBookingReturn(booking:any){
    return this.http.post(environment.bookingApiV2,booking);
  }

}
