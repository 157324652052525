<app-headerhome class="header-sec"></app-headerhome>

<div class="theme-page-section theme-page-section-dark">
        <div class="container">
          <div class="theme-search-area _mob-h theme-search-area-white">
            <div class="row">
              <div class="col-md-2 ">
                <div class="theme-search-area-header theme-search-area-header-sm">
                  <h1 class="theme-search-area-title">{{ pickUpInit }} - {{ dropOffInit }}</h1>
                  <p class="theme-search-area-subtitle">{{ travelDateInit }}</p>
                </div>
              </div>
              <div class="col-md-10 ">
                <div class="theme-search-area-form" id="hero-search-form">
                    <form  autocomplete="off" novalidate #reactiveForm = "ngForm" (ngSubmit)="submitWebForm(reactiveForm.value)">
                  <div class="row" data-gutter="10">

                        <div class="col-md-3 ">
                          <div class="theme-search-area-section first theme-search-area-section-fade-white theme-search-area-section-sm theme-search-area-section-no-border theme-search-area-section-curved">
                            <div class="theme-search-area-section-inner headerpickup">
                              <ng-select2 class="booking-custom" [data]="data"
                                      name="pickup"
                                      [(ngModel)]="pickup">
                                </ng-select2>

                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 ">
                          <div class="theme-search-area-section theme-search-area-section-fade-white theme-search-area-section-sm theme-search-area-section-no-border theme-search-area-section-curved">
                            <div class="theme-search-area-section-inner headerdropoff">
                              <ng-select2 class="booking-custom" [data]="data"
                                  name="dropoff"
                                  [(ngModel)]="dropoff">
                                </ng-select2>
                            </div>
                          </div>
                        </div>

                    <div class="col-md-3 ">
                      <div class="theme-search-area-section theme-search-area-section-fade-white theme-search-area-section-sm theme-search-area-section-no-border theme-search-area-section-curved">
                        <div class="theme-search-area-section-inner">
                            <i class="theme-search-area-section-icon lin lin-calendar"></i>
                            <input required autocomplete="off" type="text"
                            placeholder="Datepicker"
                            class="theme-search-area-section-input"
                            name="traveldate"
                            [(ngModel)]="traveldate"
                            [minDate]="today"
                            #dp="bsDatepicker"
                            bsDatepicker
                            [bsConfig]="{ isAnimated: true,adaptivePosition: true }"
                            [bsValue]="bsValue">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <button class="theme-search-area-submit _mt-0 _tt-uc theme-search-area-submit-no-border theme-search-area-submit-curved theme-search-area-submit-sm theme-search-area-submit-primary">Search</button>
                    </div>
                  </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="theme-search-area-inline _desk-h theme-search-area-inline-white">
            <h4 class="theme-search-area-inline-title">{{ this.bookingdetails.pickupname}} - {{this.bookingdetails.dropoffname}}</h4>
            <p class="theme-search-area-inline-details">{{this.bookingdetails.traveldate}}</p>
            <a class="theme-search-area-inline-link magnific-inline" href="#searchEditModal">
              <button class="theme-search-area-submit _mt-0 _tt-uc theme-search-area-submit-no-border theme-search-area-submit-curved theme-search-area-submit-sm theme-search-area-submit-periska">
              <i class="fa fa-pencil"></i>Edit
              </button>
            </a>
            <div class="magnific-popup magnific-popup-sm mfp-hide" id="searchEditModal">
              <div class="theme-search-area theme-search-area-vert">
                <div class="theme-search-area-header">
                  <h1 class="theme-search-area-title theme-search-area-title-sm">Edit your Search</h1>
                  <p class="theme-search-area-subtitle">Prices might be different from current results</p>
                </div>
                <form  autocomplete="off" novalidate [formGroup]="reactiveMobileForm" (ngSubmit)="submitMobileForm()">
                <div class="theme-search-area-form">
                  <div class="theme-search-area-section first theme-search-area-section-curved">
                    <label class="theme-search-area-section-label">Pick Up</label>
                    <div class="theme-search-area-section-inner">
                    <ng-select2 [data]="data"
                            bindLabel="name"
                            formControlName="pickup">
                     </ng-select2>

                    </div>
                  </div>
                  <div class="theme-search-area-section theme-search-area-section-curved">
                    <label class="theme-search-area-section-label">Drop Off</label>
                    <div class="theme-search-area-section-inner">
                        <ng-select2 [data]="data"
                            bindLabel="name"
                            formControlName="pickup">
                     </ng-select2>
                    </div>
                  </div>
                  <div class="row" data-gutter="10">
                    <div class="col-md-6 ">
                      <div class="theme-search-area-section theme-search-area-section-curved">
                        <label class="theme-search-area-section-label">Travel Date</label>
                        <div class="theme-search-area-section-inner">
                            <i class="theme-search-area-section-icon lin lin-calendar"></i>
                            <input required autocomplete="off" type="text"
                            placeholder="Datepicker"
                            class="theme-search-area-section-input"
                            formControlName="traveldate"
                            [minDate]="today"
                            #dp="bsDatepicker"
                            bsDatepicker
                            [bsConfig]="{ isAnimated: true,adaptivePosition: true }"
                            [bsValue]="bsValue">
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="row" data-gutter="10">
                    <div class="col-md-6 ">
                      <div class="theme-search-area-section theme-search-area-section-curved">
                        <label class="theme-search-area-section-label">Travel Date</label>
                        <div class="theme-search-area-section-inner">
                            <i class="theme-search-area-section-icon lin lin-calendar"></i>
                            <input required autocomplete="off" type="text"
                            placeholder="Datepicker"
                            class="theme-search-area-section-input"
                            formControlName="traveldate"
                            [minDate]="today"
                            #dp="bsDatepicker"
                            bsDatepicker
                            [bsConfig]="{ isAnimated: true,adaptivePosition: true }"
                            [bsValue]="bsValue">
                        </div>
                      </div>
                    </div>

                  </div>
                  <button class="theme-search-area-submit _mt-0 _tt-uc theme-search-area-submit-curved">Change</button>
                </div>
                </form>
              </div>
            </div>
          </div>
        </div>
</div>
    <div class="theme-page-section margin-grey">
      <div class="container">
        <div class="bus-listing-page  header-displayed">
            <div class="page-content row">
                <div class="col-md-12">
                        <h2 *ngIf="isReturnTicket" class="theme-search-area-title text-center">Return Ticket</h2>
                </div>
                <div class=" col-md-12" id="search-results-wrapper">
                    <div class="col-md-12">
                            <h2 *ngIf="isReturnTicket" class="theme-search-area-title text-center">Return Ticket</h2>
                    </div>
                <h2 class="city-labels">
                  {{fromPlaceholder}}
                  →
                  {{toPlaceholder}}
                </h2>
                <div class="row">
                        <div class="col-xs-12 col-no-gap visible-sm visible-lg">
                                <ul class="dates ">
                                        <li class="prev-container">
                                            <a *ngIf="!checkDates()" class="prev"  (click)="changeDate(beforeyesterday)" >
                                            ‹
                                    </a></li>
                                    <li  class="yesterday-container">
                                        <a *ngIf="!checkDates()" class="yesterday"  (click)="changeDate(yesterday)">
                                            <strong>
                                                    {{yesterday  | date:'EEEE, d MMM'}}
                                            </strong></a>
                                    </li>
                                    <li class="active-container"><a class="active" [routerLink]="[]"  (click)="changeDate(travelDateInit)" >
                                            <strong>
                                                    {{travelDateInit  | date:'EEEE, d MMM'}}
                                            </strong></a></li>
                                    <li class="tomorrow-container"><a class="tomorrow" [routerLink]="[]" (click)="changeDate(tomorrow)">
                                            <strong>
                                                    {{tomorrow  | date:'EEEE, d MMM'}}
                                            </strong></a></li>
                                    <li class="next-container"><a class="next" [routerLink]="[]" (click)="changeDate(aftertomorrow)">
                                            ›
                                        </a></li>
                                </ul>
                        </div>
                </div>
                <div class="theme-search-results search-result-multiselect">

                    <div class="theme-search-results-item search-result" id="search-result-direct">
                        <div class="row">

                            <div class="b col-xs-12 rides-list currency-eur">
                              <div class="sr-head row visible-sm visible-lg">
                                  <div class="col-xs-3 col-x3-offset-2 time-transfer">
                                      <div class="row">
                                          <div class="col-xs-12 ride-timing">Departs/Arrives
                                              <span class="visible-xs-inline visible-md-inline">, Duration
                                                  </span></div>
                                      </div>
                                  </div>
                                  <div class="col-xs-7">
                                      <div class="row">
                                          <div class="col-sm-3 col-lg-3 visible-sm visible-lg">Depature Time
                                              </div>
                                          <div class="col-xs-12 col-sm-9 col-md-12 col-lg-9">
                                              <div class="row">
                                                  <div class="col-xs-12 col-sm-4 col-md-12 col-lg-5 total">
                                                      Total
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                                <div id="results-group-container-direct" class="result-date-container">
                                        <app-results-loader *ngIf="isResultsLoading"></app-results-loader>
                                        <app-nobuses  *ngIf="resultsholder.length == 0"></app-nobuses>
                                    <div  *ngFor="let result of results;let i = index" class=" ride-item-pair  row" >
                                        <div class="col-xs-12">
                                            <div class="sr-row row  ride--booked js-ride--booked _pd-10">
                                              <div  class="col-xs-3 time-transfer">
                                                  <img  class="mobile-images _mt-10" width="100" alt="Soores Bus" src="assets/img/images/soores.png">
                                                  <br >
                                                  <p  class="Badge-Bus _mt-20"> Soores </p>
                                                  <!-- <p *ngIf="!result.fleet.registration_number.includes(fromPlaceholder+'-')" class="text-danger  badge _mb-10">Highway bus</p> -->
                                                  <div class=" hidden-sm hidden-md hidden-lg  ">
                                                    <small>{{ result.schedule.start_time}}</small>
                                                    <small>Hrs.</small>
                                                 </div>
                                              </div>
                                                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                                    <div class="row">
                                                        <div class="col-sm-4 col-lg-4 visible-sm visible-lg">
                                                            <div class="row">
                                                                <div  class="col-xs-12 ">
                                                                  <div class=" ride-stations">
                                                                      <div  class="bus-info u-ib u-v-align-middle">
                                                                    	<h6  class="left-wing city-labels-list u-ib _mr-5">
                                                                    	 {{ pickUpInit}}
                                                                    	</h6>
                                                                    	<div  class="timeline-widget u-ib">
                                                                    		<div  class="c-timeline-wrapper horizontal">
                                                                    			<div  class="label tl first" style="font-size: 20px;"></div>
                                                                    			<div  class="label tl last" style="font-size: 20px;"></div>
                                                                    			<div  class="c-timeline-booking" style="height: 3px; background-color: rgb(187, 187, 187);">
                                                                    			</div>
                                                                    			<div  class="label br first" style="font-size: 20px;"></div>
                                                                    			<div  class="label br" style="font-size: 20px;"></div>
                                                                    			<div  class="label br last" style="font-size: 20px;"></div>
                                                                    		</div>
                                                                    	</div>
                                                                    	<h6  class="right-wing city-labels-list u-ib _ml-5">
                                                                    		  {{ dropOffInit}}
                                                                    	</h6>
                                                                    </div>
                                                                  </div>
                                                                    <div class="duration ride__duration ride__duration-messages text-center">
                                                                            {{ result.schedule.start_time}}
                                                                            <span class=" ride__duration-suffix duration-suffix">Hrs.</span>
                                                                   </div>

                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div class="col-sm-8 col-lg-8 price-actions">
                                                            <div class="row">
                                                                <div
                                                                    class="col-xs-12 col-sm-4 col-md-12 col-lg-5 ">

                                                                    <div class="in-cart total"><span
                                                                            class="num pull-right _pb-10">Ksh {{result.seat_price.normal}}</span>
                                                                    </div>

                                                                </div>
                                                                <div class="col-xs-12 col-sm-8 col-md-12 col-lg-7 action">
                                                                   <button type="submit" class="js-ride__action__form__btn reserve" style="box-sizing: border-box;" (click)="selectSeats(result)">Select Seat</button>
                                                                   <div class="seats-notice">
                                                                        {{result.fleet.fleet_total_seats - (result.fleet.booked_seats.split(",").length - 1)}} seats left
                                                                     </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xs-12">
                                                <div *ngIf="dataLoading" class="seat-layout-loader"><div class="c-loader"><div class="c-loader-inner"><div class="loader"></div></div></div><span class="close-button ixi-icon-cross"></span></div>
                                                  <div  *ngIf="result.hide_from_ui">
                                                        <div class="seat-book-cntr visible-sm visible-lg"   >
                                                            <div class="seat-book-error"></div>
                                                            <div class="center-wrapper">
                                                                <div class="seat-layout-cntr">
                                                                    <div class="seat-layout-heading">SELECT A SEAT</div>
                                                                     <!-- Start 62 bus seater seat layout -->
                                                                    <div *ngIf="result.fleet.total_seats == 62" class="seat-layout">
                                                                        <div class="bus-steering u-ib"><img class=""
                                                                                src="assets/img/greenline/bus_steering.png">
                                                                        </div>
                                                                        <div class="seat-rows u-ib">
                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('1A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('1A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '1A')" >
                                                                                <div class="top"></div>
                                                                                <div class="right"></div>
                                                                                <div class="bottom"></div>
                                                                                <div class="back"></div>
                                                                                <div class="seat-no">1A</div>
                                                                            </div>
                                                                        </div>
                                                                        <div  class="seat-rows u-ib">
                                                                            <div class="seat-row">
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('3B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('3B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '3B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">3B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('6B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('6B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'"  (click)="seatSelect($event,result, '6B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">6B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('9B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('9B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '9B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">9B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('12B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('12B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '12B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">12B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('15B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('15B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '15B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">15B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('18B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('18B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '18B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">18B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('21B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('21B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '21B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">21B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('24B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('24B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '24B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">24B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('27B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('27B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '27B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">27B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('30B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('30B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '30B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">30B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('33B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('33B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '33B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">33B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('36B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('36B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '36B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">36B</div>
                                                                                </div>

                                                                            </div>
                                                                            <div class="seat-row">
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('2B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('2B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '2B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">2B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('5B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('5B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '5B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">5B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('8B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('8B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '8B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">8B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('11B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('11B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '11B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">11B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('14B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('14B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '14B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">14B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('17B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('17B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '17B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">17B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('20B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('20B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '20B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">20B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('23B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('23B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '23B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">23B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('26B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('26B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '26B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">26B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('29B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('29B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '29B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">29B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('32B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('32B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '32B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">32B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('35B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('35B')) ? 'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '35B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">35B</div>
                                                                                </div>

                                                                            </div>
                                                                            <div class="seat-row">
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('1B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('1B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '1B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">1B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('4B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('4B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '4B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">4B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('7B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('7B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '7B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">7B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('10B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('10B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '10B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">10B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('13B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('13B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '13B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">13B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('16B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('16B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '16B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">16B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('19B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('19B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '19B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">19B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('22B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('22B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '22B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">22B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('25B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('25B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '25B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">25B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('28B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('28B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '28B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">28B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('31B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('31B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '31B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">31B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('34B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('34B')) ? 'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '34B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">34B</div>
                                                                                </div>

                                                                            </div>
                                                                            <div class="seat-row">
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('21')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('26A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '26A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">26A</div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="seat-row">
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('3A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('3A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '3A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">3A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('5A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('5A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '5A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">5A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('7A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('7A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '7A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">7A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('9A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('9A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '9A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">9A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('11A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('11A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '11A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">11A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('13A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('13A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '13A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">13A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('15A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('15A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '15A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">15A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('17A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('17A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '17A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">17A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('19A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('19A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '19A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">19A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('21A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('21A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '21A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">21A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('23A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('23A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '23A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">23A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('25A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('25A')) ? 'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '25A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">25A</div>
                                                                                </div>

                                                                            </div>      
                                                                            
                                                                            <div class="seat-row">
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('2A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('2A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '2A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">2A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('4A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('4A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '4A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">4A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('6A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('6A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '6A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">6A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('8A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('8A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '8A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">8A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('10A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('10A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '10A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">10A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('12A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('12A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '12A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">12A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('14A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('14A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '14A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">14A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('16A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('16A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '16A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">16A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('18A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('18A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '18A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">18A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('20A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('20A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '20A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">20A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('22A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('22A')) ? 'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '22A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">22A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('24A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('24A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '24A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">24A</div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- End 62 bus seater seat layout -->
                                                                     <!-- Start 41 bus seater seat layout -->
                                                                     <div *ngIf="result.fleet.total_seats == 41" class="seat-layout">
                                                                        <div class="bus-steering u-ib"><img class=""
                                                                                src="assets/img/greenline/bus_steering.png">
                                                                        </div>
                                                                        <div class="seat-rows u-ib">
                                                                            <div class="no-single-seat"></div>
                                                                        </div>
                                                                        <div  class="seat-rows u-ib">
                                                                            <div class="seat-row">
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('2B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('2B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '2B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">2B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('4B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('4B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'"  (click)="seatSelect($event,result, '4B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">4B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('6B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('6B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '6B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">6B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('8B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('8B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '8B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">8B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('10B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('10B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '10B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">10B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('12B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('12B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '12B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">12B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('14B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('14B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '14B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">14B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('16B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('16B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '16B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">16B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('18B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('18B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '18B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">18B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('20B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('20B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '20B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">20B</div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="seat-row">
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('1B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('1B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '1B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">1B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('3B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('3B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '3B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">3B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('5B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('5B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '5B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">5B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('7B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('7B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '7B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">7B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('9B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('9B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '9B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">9B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('11B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('11B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '11B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">11B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('13B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('13B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '13B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">13B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('15B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('15B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '15B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">15B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('17B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('17B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '17B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">17B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('19B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('19B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '19B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">19B</div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="seat-row">
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('21')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('21')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '21')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">21</div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="seat-row">
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('SS')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('SS')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, 'SS')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">SS</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('4A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('4A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '4A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">4A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('6A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('6A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '6A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">6A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('8A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('8A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '8A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">8A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('10A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('10A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '10A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">10A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('12A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('12A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '12A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">12A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('14A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('14A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '14A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">14A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('16A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('16A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '16A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">16A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('18A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('18A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '18A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">18A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('20A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('20A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '20A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">20A</div>
                                                                                </div>
                                                                            </div>    
                                                                            <div class="seat-row">
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('1A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('1A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '1A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">1A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('3A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('3A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '3A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">3A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('5A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('5A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '5A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">5A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('7A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('7A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '7A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">7A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('9A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('9A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '9A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">9A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('11A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('11A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '11A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">11A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('13A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('13A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '13A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">13A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('15A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('15A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '15A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">15A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('17A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('17A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '17A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">17A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('19A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('19A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '19A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">19A</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- End 41 bus seater seat layout -->
                                                                    <!-- Start 49 bus seater seat layout -->
                                                                    <div *ngIf="result.fleet.total_seats == 49" class="seat-layout">
                                                                        <div class="bus-steering u-ib"><img class=""
                                                                                src="assets/img/greenline/bus_steering.png">
                                                                        </div>
                                                                        <div class="seat-rows u-ib">
                                                                            <div class="no-single-seat"></div>
                                                                        </div>
                                                                        <div  class="seat-rows u-ib">
                                                                            <div class="seat-row">
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('2B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('2B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '2B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">2B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('4B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('4B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'"  (click)="seatSelect($event,result, '4B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">4B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('6B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('6B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '6B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">6B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('8B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('8B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '8B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">8B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('10B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('10B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '10B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">10B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('12B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('12B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '12B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">12B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('14B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('14B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '14B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">14B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('16B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('16B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '16B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">16B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('18B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('18B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '18B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">18B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('20B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('20B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '20B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">20B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('22B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('22B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '22B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">22B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('24B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('24B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '24B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">24B</div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="seat-row">
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('1B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('1B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '1B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">1B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('3B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('3B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '3B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">3B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('5B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('5B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '5B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">5B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('7B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('7B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '7B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">7B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('9B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('9B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '9B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">9B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('11B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('11B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '11B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">11B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('13B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('13B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '13B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">13B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('15B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('15B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '15B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">15B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('17B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('17B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '17B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">17B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('19B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('19B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '19B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">19B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('21B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('21B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '21B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">21B</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('23B')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('23B')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '23B')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">23B</div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="seat-row">
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div class="no-single-seat"></div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('25')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('25')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '25')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">25</div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="seat-row">
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('SS')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('SS')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, 'SS')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">SS</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('4A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('4A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '4A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">4A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('6A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('6A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '6A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">6A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('8A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('8A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '8A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">8A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('10A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('10A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '10A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">10A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('12A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('12A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '12A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">12A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('14A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('14A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '14A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">14A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('16A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('16A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '16A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">16A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('18A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('18A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '18A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">18A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('20A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('20A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '20A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">20A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('22A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('22A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '22A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">22A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('24A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('24A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '24A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">24A</div>
                                                                                </div>
                                                                            </div>    
                                                                            <div class="seat-row">
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('1A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('1A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '1A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">1A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('3A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('3A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '3A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">3A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('5A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('5A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '5A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">5A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('7A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('7A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '7A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">7A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('9A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('9A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '9A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">9A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('11A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('11A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '11A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">11A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('13A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('13A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '13A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">13A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('15A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('15A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '15A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">15A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('17A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('17A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '17A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">17A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('19A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('19A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '19A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">19A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('21A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('21A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '21A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">21A</div>
                                                                                </div>
                                                                                <div [ngClass]="(result.fleet.seat_numbers.includes('23A')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('23A')) ?'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, '23A')" >
                                                                                    <div class="top"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                    <div class="seat-no">23A</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- End 49 bus seater seat layout -->

                                                                    <div class="seat-legends">
                                                                        <div class="seat-legend u-ib">
                                                                            <div class="single-seat-legend u-ib ">
                                                                                <div class="top"></div>
                                                                                <div class="right"></div>
                                                                                <div class="bottom"></div>
                                                                                <div class="back"></div>
                                                                            </div>
                                                                            <div class="u-ib text">Available</div>
                                                                        </div>
                                                                        <div class="seat-legend u-ib">
                                                                            <div class="single-seat-legend u-ib unavailable">
                                                                                <div class="top"></div>
                                                                                <div class="right"></div>
                                                                                <div class="bottom"></div>
                                                                                <div class="back"></div>
                                                                            </div>
                                                                            <div class="u-ib text">Occupied</div>
                                                                        </div>
                                                                        <div class="seat-legend u-ib">
                                                                            <div class="single-seat-legend u-ib selected">
                                                                                <div class="top"></div>
                                                                                <div class="right"></div>
                                                                                <div class="bottom"></div>
                                                                                <div class="back"></div>
                                                                            </div>
                                                                            <div class="u-ib text">Selected</div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="book-section" *ngIf="totalFare > 0">
                                                                <div class="bus-booking-summary">
                                                                    <div class="summ-heading">Selected Seat </div>
                                                                    <span *ngFor="let seatvalue of seatsSelected" class="selected-seats">{{seatvalue}}<b *ngIf="seatsSelected.length > 1">, </b></span>
                                                                    <span *ngIf="seatsSelected.length ==0">-</span>
                                                                    <div class="fare-dtl-row total">
                                                                        <div class="left-wing u-ib u-v-align-middle">Fare: </div>
                                                                        <div class="right-wing u-ib u-v-align-middle">
                                                                            <div class="c-price-display u-text-ellipsis " title=""><span class="icon"><i
                                                                                        class="ixi-icon-inr icon" aria-hidden="true"></i></span>
                                                                                        <span  class="">{{totalFare}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <button (click)="submitForm(result)" type="submit" [ngClass]="( totalFare > 0) ? 'enabled':'disabled'"  class="booking-btn  u-link">BOOK NOW</button>
                                                                </div>
                                                            </div>
                                                            <span (click)="toggleShow(result)" class="close-button "><i class="fa fa-times fa-2x" aria-hidden="true"></i></span>
                                                         </div>
                                                         <div class="seat-book-cntr-mobile hidden-sm hidden-md hidden-lg"  *ngIf="result.hide_from_ui">
                                                            <div class="center-wrapper">
                                                                <div class="seat-layout-cntr">
                                                                    <div class="seat-layout-heading">SELECT A SEAT</div>
                                                                    <div class="seat-legends">
                                                                            <div class="seat-legend u-ib">
                                                                                <div class="single-seat-legend u-ib ">
                                                                                    <div class="top"></div>
                                                                                    <div class="left"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                </div>
                                                                                <div class="u-ib text">Available</div>
                                                                            </div>
                                                                            <div class="seat-legend u-ib">
                                                                                <div class="single-seat-legend u-ib unavailable">
                                                                                    <div class="top"></div>
                                                                                    <div class="left"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                </div>
                                                                                <div class="u-ib text">Occupied</div>
                                                                            </div>
                                                                            <div class="seat-legend u-ib">
                                                                                <div class="single-seat-legend u-ib selected">
                                                                                    <div class="top"></div>
                                                                                    <div class="left"></div>
                                                                                    <div class="right"></div>
                                                                                    <div class="bottom"></div>
                                                                                    <div class="back"></div>
                                                                                </div>
                                                                                <div class="u-ib text">Selected</div>
                                                                            </div>

                                                                    </div>
                                                                    <div class="seat-layout">
                                                                        <div class="seat-rows u-ib">
                                                                          <div class="bus-steering u-ib">
                                                                              <div class="seat-row mt_5">
                                                                                  <div class="no-single-seat"></div>
                                                                                  <div class="no-single-seat"></div>
                                                                                  <div class="no-single-seat"></div>
                                                                                  <div class="no-single-seat"></div>
                                                                                  <div class="no-single-seat">
                                                                                      <img class="" src="assets/img/greenline/bus_steering.png">
                                                                                  </div>
                                                                                </div>


                                                                        </div>

                                                                        <div class="first-seats">
                                                                            <div class="seat-row mt_5">
                                                                              <div [ngClass]="(result.fleet.seat_numbers.includes('1'))?'':'unavailable'" (click)="seatSelect($event,result, 1)" class="seat u-ib single-seat">
                                                                                  <div class="top"></div>
                                                                                  <div class="left"></div>
                                                                                  <div class="right"></div>
                                                                                  <div class="bottom"></div>
                                                                                  <div class="back"></div>
                                                                                  <div class="seat-no">1</div>
                                                                              </div>
                                                                              <div [ngClass]="(result.fleet.seat_numbers.includes('2'))?'':'unavailable'" (click)="seatSelect($event,result, 2)" class="seat u-ib single-seat">
                                                                                  <div class="top"></div>
                                                                                  <div class="left"></div>
                                                                                  <div class="right"></div>
                                                                                  <div class="bottom"></div>
                                                                                  <div class="back"></div>
                                                                                  <div class="seat-no">2</div>
                                                                              </div>
                                                                              <div class="no-single-seat"></div>
                                                                              <div [ngClass]="(result.fleet.seat_numbers.includes('3'))?'':'unavailable'" (click)="seatSelect($event,result, 3)" class="seat u-ib single-seat">
                                                                                  <div class="top"></div>
                                                                                  <div class="left"></div>
                                                                                  <div class="right"></div>
                                                                                  <div class="bottom"></div>
                                                                                  <div class="back"></div>
                                                                                  <div class="seat-no">3</div>
                                                                              </div>
                                                                              <div [ngClass]="(result.fleet.seat_numbers.includes('4'))?'':'unavailable'" (click)="seatSelect($event,result, 4)" class="seat u-ib single-seat">
                                                                                  <div class="top"></div>
                                                                                  <div class="left"></div>
                                                                                  <div class="right"></div>
                                                                                  <div class="bottom"></div>
                                                                                  <div class="back"></div>
                                                                                  <div class="seat-no">4</div>
                                                                              </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="seat-row">
                                                                              <div [ngClass]="(result.fleet.seat_numbers.includes('5'))?'':'unavailable'" (click)="seatSelect($event,result, 5)" class="seat u-ib single-seat">
                                                                                  <div class="top"></div>
                                                                                  <div class="left"></div>
                                                                                  <div class="right"></div>
                                                                                  <div class="bottom"></div>
                                                                                  <div class="back"></div>
                                                                                  <div class="seat-no">5</div>
                                                                              </div>
                                                                              <div [ngClass]="(result.fleet.seat_numbers.includes('6'))?'':'unavailable'" (click)="seatSelect($event,result, 6)" class="seat u-ib single-seat">
                                                                                  <div class="top"></div>
                                                                                  <div class="left"></div>
                                                                                  <div class="right"></div>
                                                                                  <div class="bottom"></div>
                                                                                  <div class="back"></div>
                                                                                  <div class="seat-no">6</div>
                                                                              </div>
                                                                              <div class="no-single-seat"></div>
                                                                              <div [ngClass]="(result.fleet.seat_numbers.includes('7'))?'':'unavailable'" (click)="seatSelect($event,result, 7)" class="seat u-ib single-seat">
                                                                                  <div class="top"></div>
                                                                                  <div class="left"></div>
                                                                                  <div class="right"></div>
                                                                                  <div class="bottom"></div>
                                                                                  <div class="back"></div>
                                                                                  <div class="seat-no">7</div>
                                                                              </div>
                                                                              <div [ngClass]="(result.fleet.seat_numbers.includes('8'))?'':'unavailable'" (click)="seatSelect($event,result, 8)" class="seat u-ib single-seat">
                                                                                  <div class="top"></div>
                                                                                  <div class="left"></div>
                                                                                  <div class="right"></div>
                                                                                  <div class="bottom"></div>
                                                                                  <div class="back"></div>
                                                                                  <div class="seat-no">8</div>
                                                                              </div>
                                                                        </div>
                                                                        <div class="seat-row">
                                                                          <div [ngClass]="(result.fleet.seat_numbers.includes('9'))?'':'unavailable'" (click)="seatSelect($event,result, 9)" class="seat u-ib single-seat">
                                                                              <div class="top"></div>
                                                                              <div class="left"></div>
                                                                              <div class="right"></div>
                                                                              <div class="bottom"></div>
                                                                              <div class="back"></div>
                                                                              <div class="seat-no">9</div>
                                                                          </div>
                                                                          <div [ngClass]="(result.fleet.seat_numbers.includes('10'))?'':'unavailable'" (click)="seatSelect($event,result, 10)" class="seat u-ib single-seat">
                                                                              <div class="top"></div>
                                                                              <div class="left"></div>
                                                                              <div class="right"></div>
                                                                              <div class="bottom"></div>
                                                                              <div class="back"></div>
                                                                              <div class="seat-no">10</div>
                                                                          </div>
                                                                            <div class="no-single-seat"></div>
                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('11'))?'':'unavailable'" (click)="seatSelect($event,result, 11)" class="seat u-ib single-seat">
                                                                                <div class="top"></div>
                                                                                <div class="left"></div>
                                                                                <div class="right"></div>
                                                                                <div class="bottom"></div>
                                                                                <div class="back"></div>
                                                                                <div class="seat-no">11</div>
                                                                            </div>
                                                                            <div [ngClass]="(result.fleet.seat_numbers.includes('12'))?'':'unavailable'" (click)="seatSelect($event,result, 12)" class="seat u-ib single-seat">
                                                                                <div class="top"></div>
                                                                                <div class="left"></div>
                                                                                <div class="right"></div>
                                                                                <div class="bottom"></div>
                                                                                <div class="back"></div>
                                                                                <div class="seat-no">12</div>
                                                                            </div>
                                                                      </div>
                                                                      <div class="seat-row">
                                                                          <div [ngClass]="(result.fleet.seat_numbers.includes('13'))?'':'unavailable'" (click)="seatSelect($event,result, 13)" class="seat u-ib single-seat">
                                                                              <div class="top"></div>
                                                                              <div class="left"></div>
                                                                              <div class="right"></div>
                                                                              <div class="bottom"></div>
                                                                              <div class="back"></div>
                                                                              <div class="seat-no">13</div>
                                                                          </div>
                                                                          <div [ngClass]="(result.fleet.seat_numbers.includes('14'))?'':'unavailable'" (click)="seatSelect($event,result, 14)" class="seat u-ib single-seat">
                                                                              <div class="top"></div>
                                                                              <div class="left"></div>
                                                                              <div class="right"></div>
                                                                              <div class="bottom"></div>
                                                                              <div class="back"></div>
                                                                              <div class="seat-no">14</div>
                                                                          </div>
                                                                          <div class="no-single-seat"></div>
                                                                          <div [ngClass]="(result.fleet.seat_numbers.includes('15'))?'':'unavailable'" (click)="seatSelect($event,result, 15)" class="seat u-ib single-seat">
                                                                              <div class="top"></div>
                                                                              <div class="left"></div>
                                                                              <div class="right"></div>
                                                                              <div class="bottom"></div>
                                                                              <div class="back"></div>
                                                                              <div class="seat-no">15</div>
                                                                          </div>
                                                                          <div [ngClass]="(result.fleet.seat_numbers.includes('16'))?'':'unavailable'" (click)="seatSelect($event,result, 16)" class="seat u-ib single-seat">
                                                                              <div class="top"></div>
                                                                              <div class="left"></div>
                                                                              <div class="right"></div>
                                                                              <div class="bottom"></div>
                                                                              <div class="back"></div>
                                                                              <div class="seat-no">16</div>
                                                                          </div>
                                                                    </div>

                                                                    <div class="seat-row">
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('17'))?'':'unavailable'" (click)="seatSelect($event,result, 17)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">17</div>
                                                                        </div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('18'))?'':'unavailable'" (click)="seatSelect($event,result, 18)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">18</div>
                                                                        </div>
                                                                        <div class="no-single-seat"></div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('19'))?'':'unavailable'" (click)="seatSelect($event,result, 19)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">19</div>
                                                                        </div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('20'))?'':'unavailable'" (click)="seatSelect($event,result, 20)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">20</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="seat-row">
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('21'))?'':'unavailable'" (click)="seatSelect($event,result, 21)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">21</div>
                                                                        </div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('22'))?'':'unavailable'" (click)="seatSelect($event,result, 22)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">22</div>
                                                                        </div>
                                                                        <div class="no-single-seat"></div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('23'))?'':'unavailable'" (click)="seatSelect($event,result, 23)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">23</div>
                                                                        </div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('24'))?'':'unavailable'" (click)="seatSelect($event,result, 24)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">24</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="seat-row">
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('25'))?'':'unavailable'" (click)="seatSelect($event,result, 25)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">25</div>
                                                                        </div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('26'))?'':'unavailable'" (click)="seatSelect($event,result, 26)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">26</div>
                                                                        </div>
                                                                        <div class="no-single-seat"></div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('27'))?'':'unavailable'" (click)="seatSelect($event,result, 27)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">27</div>
                                                                        </div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('28'))?'':'unavailable'" (click)="seatSelect($event,result, 28)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">28</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="seat-row">
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('29'))?'':'unavailable'" (click)="seatSelect($event,result, 29)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">29</div>
                                                                        </div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('30'))?'':'unavailable'" (click)="seatSelect($event,result, 30)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">30</div>
                                                                        </div>
                                                                        <div class="no-single-seat"></div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('31'))?'':'unavailable'" (click)="seatSelect($event,result, 31)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">31</div>
                                                                        </div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('32'))?'':'unavailable'" (click)="seatSelect($event,result, 32)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">32</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="seat-row">
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('33'))?'':'unavailable'" (click)="seatSelect($event,result, 33)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">33</div>
                                                                        </div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('34'))?'':'unavailable'" (click)="seatSelect($event,result, 34)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">34</div>
                                                                        </div>
                                                                        <div class="no-single-seat"></div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('35'))?'':'unavailable'" (click)="seatSelect($event,result, 35)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">35</div>
                                                                        </div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('36'))?'':'unavailable'" (click)="seatSelect($event,result, 36)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">36</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="seat-row">
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('37'))?'':'unavailable'" (click)="seatSelect($event,result, 37)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">37</div>
                                                                        </div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('38'))?'':'unavailable'" (click)="seatSelect($event,result, 38)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">38</div>
                                                                        </div>
                                                                        <div class="no-single-seat"></div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('39'))?'':'unavailable'" (click)="seatSelect($event,result, 39)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">39</div>
                                                                        </div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('40'))?'':'unavailable'" (click)="seatSelect($event,result, 40)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">40</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="seat-row">
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('41'))?'':'unavailable'" (click)="seatSelect($event,result, 41)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">41</div>
                                                                        </div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('42'))?'':'unavailable'" (click)="seatSelect($event,result, 42)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">42</div>
                                                                        </div>
                                                                        <div class="no-single-seat"></div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('43'))?'':'unavailable'" (click)="seatSelect($event,result, 43)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">43</div>
                                                                        </div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('44'))?'':'unavailable'" (click)="seatSelect($event,result, 44)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">44</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="seat-row">
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('45'))?'':'unavailable'" (click)="seatSelect($event,result, 45)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">45</div>
                                                                        </div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('46'))?'':'unavailable'" (click)="seatSelect($event,result, 46)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">46</div>
                                                                        </div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('47'))?'':'unavailable'" (click)="seatSelect($event,result, 47)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">47</div>
                                                                        </div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('48')) ? 'seat u-ib single-seat':(bookedSeatArray.includes('48')) ? 'seat u-ib single-seat unavailable' : 'no-single-seat'" (click)="seatSelect($event,result, 48)" >
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">48</div>
                                                                        </div>
                                                                        <div [ngClass]="(result.fleet.seat_numbers.includes('49'))?'':'unavailable'" (click)="seatSelect($event,result, 49)" class="seat u-ib single-seat">
                                                                            <div class="top"></div>
                                                                            <div class="left"></div>
                                                                            <div class="right"></div>
                                                                            <div class="bottom"></div>
                                                                            <div class="back"></div>
                                                                            <div class="seat-no">49</div>
                                                                        </div>
                                                                    </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-12 hidden-sm hidden-md hidden-lg">
                                                           <div class="book-section" *ngIf="totalFare > 0">
                                                               <div class="bus-booking-summary">
                                                                   <div class="summ-heading">Selected Seat </div>
                                                                   <span *ngFor="let seatvalue of seatsSelected" class="selected-seats">{{seatvalue}}<b *ngIf="seatsSelected.length > 1">, </b></span>
                                                                   <span *ngIf="seatsSelected.length ==0">-</span>
                                                                   <div class="fare-dtl-row total">
                                                                       <div class="left-wing u-ib u-v-align-middle">Fare: </div>
                                                                       <div class="right-wing u-ib u-v-align-middle">
                                                                           <div class="c-price-display u-text-ellipsis " title=""><span class="icon"><i
                                                                                       class="ixi-icon-inr icon" aria-hidden="true"></i></span>
                                                                                       <span  class="">{{totalFare}}</span>
                                                                           </div>
                                                                       </div>
                                                                   </div>
                                                                   <button (click)="submitForm(result)" type="submit" [ngClass]="( totalFare > 0) ? 'enabled':'disabled'"  class="booking-btn  u-link">BOOK NOW</button>
                                                               </div>
                                                           </div>
                                                          </div>



                                                        <div class="u-clear"></div>

                                                  </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                </div>
                                <div class="row">
                                    <div id="multiselect-error" class="col-xs-12 rides-list" data-group="direct"
                                        style="display: none">
                                        <div class="search-result">
                                            <div class="search-result__not-found">
                                                <div class="search-result-suggestions--cities"></div>
                                                <div class="search-result__not-found__text">Unfortunately there
                                                    are no trips on this route on
                                                    the selected date. Please adjust the date of the trip to get
                                                    results.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               </div>
                <div class="offer-section u-ib u-layout-col-2">

                </div>
            </div>
        </div>
      </div>
    </div>
      <div class="theme-page-section theme-page-section-white">
        <div class="container">
          <div class="row row-col-static" id="sticky-parent">
            <div class="col-md-11">
              <div class="theme-search-results _mb-10">
              </div>
            </div>
          </div>
        </div>
      </div>

<app-footer></app-footer>
