<div class="about-wrapper">
  <app-headerhome class="header-sec"></app-headerhome>

    <!--start of about us section-->

    <div class=" _bg-mob-dark _mt-mob-15 _w-f row" style="padding-bottom: 20px;">

      <div class="buupass-home-section">

        <div class="container">
          <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">

                  <div class="section-title center" style="margin-top:30px; margin-bottom:15px;">
                      <h2 class="title-header" style="text-align: center; font-size: 20px;">About Us</h2>
                      <h4 class="title-description" style="text-align: center; font-size: 16px;">More than just Transport.</h4>
                  </div>

                  <p style="text-align: center; margin-bottom:30px;">
                    Soores Bus Limited is a new bus that provides safety and comfort to travelers. For booking purposes one can either make a phone call or use the buupass app to book. Payment after booking can be done via M-Pesa as well.    
                  </p>
              </div>
          </div>

          <h4 class="title-description" style="text-align: center; font-size: 16px; margin-bottom: 10px;">Why Soores..?</h4>

          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="padding-top: 5px;">
            <div class="col-xl-3 col-lg-3 col-md-2 col-sm-12 ">
              <span class="title-wrapper">
                <i class="icofont-wifi-alt title-icon"></i>
                <span class="title-why-us">Full Amenities</span>
              </span>
              <p style="margin-top: 5px; font-size: 14px;">
                Our buses are fitted with high speed internet connection via wifi and power outlets to charge your devices on the go.
              </p>
            </div>

            <div class="col-xl-3 col-lg-3 col-md-2 col-sm-12 ">
              <span class="title-wrapper">
                <i class="icofont-coins title-icon"></i>
                <span class="title-why-us">Flexible Payment</span>
              </span>
              <p style="margin-top: 5px; font-size: 14px;">
                We have introduced several options to book from the comfort of your home or from whichever location you are, e.g Lipa Na Mpesa, online booking.
              </p>
              <img  width="100px" src ="/assets/img/images/lipa_na_mpesa.png">
            </div>

            <div class="col-xl-3 col-lg-3 col-md-2 col-sm-12 ">
              <span class="title-wrapper">
                <i class="icofont-school-bus title-icon"></i>
                <span class="title-why-us">Comfort</span>
              </span>
              <p style="margin-top: 5px; font-size: 14px;">
                We have the most comfortable buses on the kenyan roads with lots of legroom to ensure that you reach your destination fully relaxed.
              </p>
            </div>

            <div class="col-xl-3 col-lg-3 col-md-2 col-sm-12 ">
              <span class="title-wrapper">
                <i class="icofont-delivery-time title-icon"></i>
                <span class="title-why-us">Parcel Services</span>
              </span>
              <p style="margin-top: 5px; font-size: 14px;">
                We deliver parcels to all major towns in the country.
              </p>
            </div>

          </div>
        </div>



    </div>
  </div>
  <!--End of About Us-->

<app-footer></app-footer>

</div>
