import { BrowserModule } from '@angular/platform-browser';
import { NgModule,LOCALE_ID } from '@angular/core';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
//import { BsDropdownModule } from 'ngx-bootstrap/da';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelect2Module } from 'ng-select2';

// RECOMMENDED
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import enke from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
registerLocaleData(enke);

import { AppComponent } from './app.component';
import { HeadermainComponent } from './shared/headermain/headermain.component';
import { HeaderhomeComponent } from './shared/headerhome/headerhome.component';
import { FooterComponent } from './shared/footer/footer.component';
import { PaymentsComponent } from './payments/payments.component';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { BookingComponent } from './booking/booking.component';
import { ResultsLoaderComponent } from './shared/results-loader/results-loader.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { NobusesComponent } from './nobuses/nobuses.component';
import { SuccessComponent } from './success/success.component';
import { CounterDirective } from './directives/counter.directive';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactusComponent } from './contactus/contactus.component';


@NgModule({
  declarations: [
    AppComponent,
    HeadermainComponent,
    HeaderhomeComponent,
    FooterComponent,
    PaymentsComponent,
    HomeComponent,
    NotFoundComponent,
    SpinnerComponent,
    BookingComponent,
    ResultsLoaderComponent,
    ConfirmComponent,
    NobusesComponent,
    SuccessComponent,
    CounterDirective,
    AboutusComponent,
    ContactusComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    NgSelect2Module
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
