<div class="home-wrapper">
    <app-headerhome class="header-sec"></app-headerhome>
    <section class="home-sec">
      <div class="overlay"></div>
        <div class="owl-carousel" style="width: 100%; height: 100%;"  data-items="1" data-loop="true" data-nav="false" data-autoplay="3000">
            <div class="theme-hero-area " style="width: 100%; height: 100%;" >
              <div class="theme-hero-area-bg-wrap _h-100vh _h-mob-100vh" style="width: 100%; height: 100%;" >
                  <div class="theme-hero-area-bg bg-slider-1" style="width: 100%; height: 100%;" alt="Search. Book. Go."></div>
              </div>
            </div>
            <div class="theme-hero-area _h-100vh _h-mob-100vh" style="width: 100%; height: 100%;" >
              <div class="theme-hero-area-bg-wrap" style="width: 100%; height: 100%;" >
                  <div class="theme-hero-area-bg bg-slider-2" style="width: 100%; height: 100%;"  alt="Search. Book. Go."></div>
              </div>
            </div>
            <div class="theme-hero-area">
              <div class="theme-hero-area-bg-wrap _h-100vh _h-mob-100vh">
                <div class="theme-hero-area-bg bg-slider-3" alt="Search. Book. Go"></div>
              </div>
            </div>
        </div>
        <div class="container h-100">
          <div class="d-flex h-100 text-center align-items-center">
            <div class="w-100 text-white mobile-margin">
              <h2 class="banner-title">Welcome to SOORES </h2>
              <p class="tagline"> Search. Book. Travel</p>
              <!-- start search Cmponent  -->
              <div class="tabbable container">
                <div class="tab-content _pt-15 _ph-25 _pt-10 _pb-20 _bg-w tab-content-curved">
                  <div class="tab-pane active" id="SearchAreaTabs-4" role="tab-panel">
                      <div class="theme-search-area ">
                        <div class="theme-search-area-form">
                          <form  autocomplete="off" novalidate [formGroup]="reactiveForm" (ngSubmit)="submitForm()">
                            <div class="row" data-gutter="30">
                                <div class="col-lg-3 col-md-3 col-sm-12 mobile-row">
                                    <label class="input-label">From</label><br>
                                    <ng-select2
                                              [data]="data"
                                              bindLabel="name"
                                              formControlName="pickup"
                                              [placeholder]="From">
                                    </ng-select2>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-12 mobile-row">
                                    <label class="input-label">To</label><br>
                                    <ng-select2
                                            [data]="data"
                                            bindLabel="name"
                                            formControlName="dropoff"
                                            [placeholder]="To">
                                    </ng-select2>
                                </div>
                                <div class="col-lg-3 col-md3 col-sm-12 mobile-row">
                                  <label class="input-label">Travel Date</label>
                                  <input required autocomplete="off" type="text"
                                          placeholder="Travel Date"
                                          class="input-control"
                                          formControlName="traveldate"
                                          [minDate]="today"
                                          #dp="bsDatepicker"
                                          bsDatepicker
                                          [bsConfig]="{ isAnimated: true,adaptivePosition: true }"
                                          [bsValue]="bsInlineValue">
                                </div>

                                <!-- <div class="col-lg-2 col-md-2 col-sm-12 mobile-row padded" >

                                        <label class="input-label">Return Date<span class="instruction"> * optional</span></label>

                                        <input required autocomplete="off" type="text"
                                          placeholder=""
                                          class="input-control"
                                          formControlName="returndate"
                                          [minDate]="today"
                                          #dp="bsDatepicker"
                                          bsDatepicker
                                          [bsConfig]="{ isAnimated: true,adaptivePosition: true }">
                                </div> -->
                                <div class="col-lg-2 col-md-2 col-sm-12 mobile-row">
                                    <button type="submit" [disabled]="reactiveForm.invalid" class="search-btn theme-search-area-submit  theme-search-area-submit-curved  ">SEARCH</button>
                                </div>
                            </div>
                          </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
<!--start of about us section-->
      <div class=" _bg-mob-dark _mt-mob-15 _w-f row" style="padding-bottom: 20px;">
          <div class="buupass-home-section">
            <div class="container">
              <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                      <div class="section-title center" style="margin-top:30px; margin-bottom:15px;">
                          <h2 class="title-header" style="text-align: center; font-size: 20px;">About Us</h2>
                          <h4 class="title-description" style="text-align: center; font-size: 16px;">More than just Transport.</h4>
                      </div>
                      <p style="text-align: center; margin-bottom:30px;">
                        Soores Bus Limited is a new bus that provides safety and comfort to travelers. For booking purposes one can either make a phone call or use the buupass app to book. Payment after booking can be done via M-Pesa as well.
                      </p>
                  </div>
              </div>
              <h4 class="title-description" style="text-align: center; font-size: 16px; margin-bottom: 10px;">Why Soores..?</h4>
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" style="padding-top: 5px;">
                <div class="col-xl-3 col-lg-3 col-md-2 col-sm-12 ">
                  <span class="title-wrapper">
                    <i class="icofont-wifi-alt title-icon"></i>
                    <span class="title-why-us">Full Amenities</span>
                  </span>
                  <p>
                    Our buses are fitted with high speed internet connection via wifi and power outlets to charge your devices on the go.
                  </p>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-2 col-sm-12 ">
                  <span class="title-wrapper">
                    <i class="icofont-coins title-icon"></i>
                    <span class="title-why-us">Flexible Payment</span>
                  </span>
                  <p>
                    We have introduced several options to book from the comfort of your home or from whichever location you are, e.g Lipa Na Mpesa, online booking.
                  </p>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-2 col-sm-12 ">
                  <span class="title-wrapper">
                    <i class="icofont-school-bus title-icon"></i>
                    <span class="title-why-us">Comfort</span>
                  </span>
                  <p>
                    We have the most comfortable buses on the kenyan roads with lots of legroom to ensure that you reach your destination fully relaxed.
                  </p>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-2 col-sm-12 ">
                  <span class="title-wrapper">
                    <i class="icofont-delivery-time title-icon"></i>
                    <span class="title-why-us">Parcel Services</span>
                  </span>
                  <p>
                    We deliver parcels to all major towns in the country.
                  </p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <!--End of About Us-->
    <app-footer></app-footer>
</div>
