import { Component, OnInit } from '@angular/core';
import { formatDate, PlatformLocation  } from '@angular/common';
import {Router} from "@angular/router"

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    private router:Router,
  ) { }

  ngOnInit() {
  }


  clickNairobiMy(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      dropoff: "2",
      dropoffname: "Moyale",
      pickup: "1",
      pickupname: "Nairobi",
      traveldate : traveldate,
    }

    let p=btoa(JSON.stringify(formdata));
    this.router.navigate(['/booking', p])


  }

  clickNairobiMr(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      'dropoff': "4",
      'dropoffname': "Marsabit",
      'pickup': "1",
      'pickupname': "Nairobi",
      'traveldate' : traveldate,
    }

    let p=btoa(JSON.stringify(formdata));
    this.router.navigate(['/booking', p])

  }

  clickNairobiIs(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      'dropoff': "3",
      'dropoffname': "Isiolo",
      'pickup': "1",
      'pickupname': "Nairobi",
      'traveldate' : traveldate,
    }

    let p=btoa(JSON.stringify(formdata));
    this.router.navigate(['/booking', p])
    
  }

  clickNairobiNy(){
    var traveldate = formatDate(new Date(),"yyyy-MM-dd","en-KE");

    let formdata={
      'dropoff': "19",
      'dropoffname': "Nanyuki",
      'pickup': "1",
      'pickupname': "Nairobi",
      'traveldate' : traveldate,
    }

    let p=btoa(JSON.stringify(formdata));
    this.router.navigate(['/booking', p])
    
  }

  

}
