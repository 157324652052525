<app-headerhome class="header-sec"></app-headerhome>
<div class="progress-cntr-wrpr">
    <div class="progress-cntr">
        <div class="progress-tracker-cntr transparent">
            <div class="progress-tracker">
                <div class="progress-item first ">
                    <div class="item-wrapper passed">
                        <div class="progess-item-label">Booking Passenger details</div>
                    </div>
                </div>
                <div class="progress-item current">
                    <div class="item-wrapper">
                        <div class="progess-item-label">Payment</div>
                    </div>
                </div>
                <div class="progress-item last">
                    <div class="item-wrapper">
                        <div class="progess-item-label">Ticket</div>
                    </div>
                </div>
                <div class="progress-tracker-completed" style="width: 0%;"></div>
            </div>
        </div>
    </div>
</div>
    <div class="theme-page-section margin-grey">
      <div id="container">
          <div class="bus-booking-page">

                  <div class="page-content row">

                      <div class="col-md-9 col-lg-9 col-sm-12">
                          <div class="bus-booking-summ row  _m-5 _mb-30">
                              <div class="bus-info-cntr col-md-6 u-ib u-v-align-top"><span
                                      class="u-ib u-v-align-middle bus-agent-name">{{bookingdetails.pickup}} - {{bookingdetails.dropoff}}
                                      <span class="u-ib u-v-align-middle bus-ac-row">
                                          <div class="ixi-icon-ac u-v-align-middle u-ib icon"></div>
                                      </span></span>
                                  <div class="bus-info u-ib u-v-align-middle">
                                      <div class="left-wing u-ib">{{bookingdetails.schedule.start_time}}</div>
                                      <div class="timeline-widget u-ib">
                                          <div class="c-timeline-wrapper horizontal">
                                              <div class="label tl first" style="font-size: 12px;"></div>
                                              <div class="label tl " style="font-size: 12px;">{{bookingdetails.schedule.duration}} hr</div>
                                              <div class="label tl last" style="font-size: 12px;"></div>
                                              <div class="c-timeline"
                                                  style="height: 2px; background-color: rgb(187, 187, 187);">
                                                  <div class="dot"
                                                      style="width: 6px; height: 6px; border-radius: 6px; background-color: rgb(117, 117, 117); margin-top: -2px;">
                                                  </div>
                                                  <div class="dot"
                                                      style="width: 6px; height: 6px; border-radius: 6px; background-color: rgb(117, 117, 117); margin-top: -2px;">
                                                  </div>
                                              </div>
                                              <div class="label br first" style="font-size: 12px;"></div>
                                              <div class="label br " style="font-size: 12px;"></div>
                                              <div class="label br last" style="font-size: 12px;"></div>
                                          </div>
                                      </div>
                                      <div class="right-wing u-ib">{{bookingdetails.schedule.end_time}}</div>
                                  </div>

                              </div>
                              <div class="u-ib col-md-6  u-v-align-top pickup-info-cntr">
                                  <div class="info pickup">
                                      <div class="info-heading">Pickup Point</div>
                                      <div class="info-value"><span>{{bookingdetails.pickup}}
                                          &nbsp;&nbsp;&nbsp;<span>{{bookingdetails.traveldate}}</span><span
                                                  class="dot"></span><span>{{bookingdetails.schedule.start_time}} </span></span></div>
                                  </div>
                                  <div class="info dropoff">
                                      <div class="info-heading">Dropoff Point</div>
                                      <div class="info-value"><span
                                              class="u-ib">{{bookingdetails.dropoff}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{{bookingdetails.traveldate}}</span><span
                                                  class="dot"></span><span>{{bookingdetails.schedule.end_time}}</span></span></div>
                                  </div>
                                  <div class="info seats">
                                      <div class="info-heading">Seats Selected</div>
                                      <div *ngFor="let seat of bookingdetails.seatsSelected" class="info-value">{{seat}} ,</div>
                                  </div>
                                  <div [routerLink]="['/home']" class="info modify-booking u-link">MODIFY BOOKING</div>
                              </div>
                          </div>
                          <form class="makeFlex" id="ngForm" [formGroup]="paymentForm" (ngSubmit)="submitForm()">
                          <div class="trvlrs-dtl _m-5 _mb-20">
                              <div class="c-accordion-item u-box expanded">
                                  <div class="accordion-hdr ">
                                      <div class="accordion-hdr-cntnt">
                                          <div class="trvlr-hdr">
                                              <div>Traveller Details</div>
                                              <div>1 traveller</div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="accordion-body" style="display: block;">
                                      <div class="general-error"></div>
                                      <div class="row">
                                          <div class="col-md-11">
                                            <button *ngIf="bookingdetails.seatsSelected.length > 1" type="button" class="btn btn-primary-invert btn-shadow text-upcase theme-footer-subscribe-btn pull-right" name="button" (click)="copyDetails()">
                                              <i class="fa fa-clipboard" aria-hidden="true"></i>  {{copyMessage}}
                                            </button>
                                          </div>
                                      </div>
                                      <div class="trvlr-forms-cntnr u-ib">
                                          <div class="trvlr-form-cntnr">
                                              <div class="trvlr-form">

                                                  <div *ngFor="let seat of bookingdetails.seatsSelected; let seatno = index">
                                                      <div class="form-hdr _pb-10 _pt-10">Traveller {{seatno+1}} Seat {{seat}} </div>
                                                      <div class="row _pd-30" data-gutter="30">
                                                        <div class="col-md-4 ">
                                                          <div class="theme-search-area-section first theme-search-area-section-line">
                                                              <label for="fname{{seat}}" class="theme-search-area-section-label">Firstname</label>
                                                              <div class="theme-search-area-section-inner">
                                                              <input class="theme-search-area-section-input "
                                                                      type="text"
                                                                      placeholder=""
                                                                      name="fname{{seat}}"
                                                                      id="fname{{seatno+1}}"
                                                                      tabindex="0"
                                                                      autocomplete="off"
                                                                      formControlName="fname{{seat}}"
                                                                >
                                                              </div>
                                                              <small class="invalid-select" *ngIf="paymentForm.get('fname'+seat).touched && paymentForm.get('fname'+seat).hasError('pattern')">Your firstname should not contain numbers or spaces!</small>
                                                              <small class="invalid-select" *ngIf="paymentForm.get('fname'+seat).touched && paymentForm.get('fname'+seat).hasError('required')">Your firstname is required!</small>
                                                              <small class="invalid-select" *ngIf="paymentForm.get('fname'+seat).touched && paymentForm.get('fname'+seat).hasError('minlength')">Your firstname should be correct!</small>
                                                          </div>
                                                        </div>
                                                          <div class="col-md-4 ">
                                                                  <div class="theme-search-area-section first theme-search-area-section-line">
                                                                  <label  for="lname{{seat}}" class="theme-search-area-section-label">Lastname</label>
                                                                  <div class="theme-search-area-section-inner">
                                                                      <input class="theme-search-area-section-input "
                                                                      type="text"
                                                                      placeholder=""
                                                                      name="lname{{seat}}"
                                                                      id="lname{{seatno+1}}"
                                                                      tabindex="0"
                                                                      autocomplete="off"
                                                                      formControlName="lname{{seat}}"
                                                                      >
                                                                  </div>
                                                                  <small class="invalid-select" *ngIf="paymentForm.get('lname'+seat).touched && paymentForm.get('lname'+seat).hasError('pattern')">Your firstname should not contain numbers or spaces!</small>
                                                                  <small class="invalid-select" *ngIf="paymentForm.get('lname'+seat).touched && paymentForm.get('lname'+seat).hasError('required')">Your firstname is required!</small>
                                                                  <small class="invalid-select" *ngIf="paymentForm.get('lname'+seat).touched && paymentForm.get('lname'+seat).hasError('minlength')">Your firstname should be correct!</small>
                                                                  </div>
                                                          </div>
                                                      </div>
                                                        <div class="row _pt-20 _pd-30" data-gutter="30">
                                                          <div class="col-md-4 ">
                                                                  <div class="theme-search-area-section first theme-search-area-section-line">
                                                                  <label for="idno{{seat}}" class="theme-search-area-section-label">ID/Passport</label>
                                                                  <div class="theme-search-area-section-inner">
                                                                      <input class="theme-search-area-section-input "
                                                                       type="text"
                                                                       name="idno{{seat}}"
                                                                       id="idno{{seatno+1}}"
                                                                       placeholder=""
                                                                       tabindex="0"
                                                                       autocomplete="off"
                                                                       formControlName="idno{{seat}}"
                                                                       >
                                                                  </div>
                                                                  <small class="invalid-select" *ngIf="paymentForm.get('idno'+seat).touched && paymentForm.get('idno'+seat).hasError('minlength')">Your Id/passport should not be valid</small>
                                                                  <small class="invalid-select" *ngIf="paymentForm.get('idno'+seat).touched && paymentForm.get('idno'+seat).hasError('required')">Your Id/passport  is required!</small>
                                                                  </div>
                                                          </div>
                                                          <div class="col-md-4 ">
                                                                  <div class="theme-search-area-section first theme-search-area-section-line">
                                                                  <label  for="residence{{seat}}" class="theme-search-area-section-label">Residence</label>
                                                                  <div class="theme-search-area-section-inner">

                                                                      <input class="theme-search-area-section-input "
                                                                      type="text"
                                                                      placeholder=""
                                                                      name="residence{{seat}}"
                                                                      id="residence{{seatno+1}}"
                                                                      tabindex="0"
                                                                      autocomplete="off"
                                                                      formControlName="residence{{seat}}"
                                                                      >
                                                                  </div>
                                                                  <small class="invalid-select" *ngIf="paymentForm.get('residence'+seat).touched && paymentForm.get('residence'+seat).hasError('pattern')">Your residence should not contain numbers</small>
                                                                  <small class="invalid-select" *ngIf="paymentForm.get('residence'+seat).touched && paymentForm.get('residence'+seat).hasError('required')">This field is required!</small>
                                                                  </div>
                                                          </div>

                                                      </div>
                                                      <div class="row _pt-20 _pd-30">
                                                          <div class="col-md-5">
                                                              <div class="theme-search-area-section first theme-search-area-section-line">
                                                                <label for="phoneno" class="theme-search-area-section-label">Phone number</label>
                                                                <div class="theme-search-area-section-inner">
                                                                  <input
                                                                  class="theme-search-area-section-input"
                                                                   type="text"
                                                                   placeholder="e.g 0722000000"
                                                                  name="phoneno{{seat}}"
                                                                  id="phoneno{{seatno+1}}"
                                                                  tabindex="0"
                                                                  formControlName="phoneno{{seat}}"
                                                                  >
                                                                </div>
                                                                <small class="invalid-select" *ngIf="paymentForm.get('phoneno'+seat).touched && paymentForm.get('phoneno'+seat).hasError('minlength')">should not be less or greater than 10</small>
                                                                <small class="invalid-select" *ngIf="paymentForm.get('phoneno'+seat).touched && paymentForm.get('phoneno'+seat).hasError('required')">this field is required!</small>
                                                                <small class="invalid-select" *ngIf="paymentForm.get('phoneno'+seat).touched && paymentForm.get('phoneno'+seat).hasError('pattern')">Your phone number should be valid!</small>

                                                            </div>
                                                          </div>
                                                        </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="trvlr-list-cntnr u-ib">
                                          <div class="c-trvlr-list-wrpr">
                                              <div class="c-trvlr-list-cntnr u-cstm-scroll" style="max-height: 250px;">
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="c-accordion-item u-box expanded  _m-5 _mb-20">
                              <div class="accordion-hdr ">
                                  <div class="accordion-hdr-cntnt">
                                      <div class="contact-hdr">Payment Number:<span class="sub-text">Your ticket SMS will
                                              be sent to this number and email</span></div>
                                  </div>
                              </div>
                              <div class="accordion-body" style="display: block;">
                                  <div class="contact-group">
                                      <div class="row" data-gutter="30">
                                              <div class="col-md-3 ">
                                                      <div class="theme-search-area-section first theme-search-area-section-line">
                                                        <label class="theme-search-area-section-label">M-pesa No.</label>
                                                        <div class="theme-search-area-section-inner">
                                                          <input class="theme-search-area-section-input "
                                                           placeholder="Mpesa No"
                                                           name="mobileNumber"
                                                           type="text"
                                                           id="mobileNumber"
                                                           formControlName="mobileNumber">
                                                        </div>
                                                        <small class="invalid-select" *ngIf="paymentForm.get('mobileNumber').touched && paymentForm.get('mobileNumber').hasError('minlength')">Your phone number should not be less than 10. </small>
                                                        <small class="invalid-select" *ngIf="paymentForm.get('mobileNumber').touched && paymentForm.get('mobileNumber').hasError('required')">Your phone number required!</small>
                                                        <small class="invalid-select" *ngIf="paymentForm.get('mobileNumber').touched && paymentForm.get('mobileNumber').hasError('pattern')">Your phone number should be valid!</small>
                                                      </div>
                                                </div>
                                        </div>
                                  </div>
                              </div>
                          </div>
                          </form>
                      </div>
                      <div class="col-md-3 col-lg-3 col-sm-12" style="transform: translateY(0px);">
                          <div class="fare-dtl-acc ">
                              <div class="c-accordion-item u-box expanded collapsible diff-collapsed">
                                  <div class="accordion-hdr  collapsible">
                                      <div class="accordion-hdr-cntnt">
                                          <div class="fare-hdr"><span>Fare Summary</span> <span>({{ bookingdetails.seatsSelected.length }} traveller(s)</span></div>
                                      </div><span class="ixi-icon-chevron"></span>
                                  </div>
                                  <div class="accordion-body" style="display: block;">
                                      <div class="fare-dtl">
                                          <div class="fare-dtl-row">
                                              <div class="left-wing">Base Fare</div>
                                              <div class="right-wing">
                                                  <div class="c-price-display u-text-ellipsis " title=""><span
                                                          class="icon"><i class="ixi-icon-inr icon" aria-hidden="true"></i></span><span class="">Ksh {{bookingdetails.totalfare}}</span>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="fare-dtl-row">
                                              <div class="left-wing">Taxes &amp; Fees</div>
                                              <div class="right-wing taxes"><span class="ixi-icon-info"
                                                      style="position: relative;"></span>
                                                  <div class="c-price-display u-text-ellipsis " title=""><span
                                                          class="icon"><i class="ixi-icon-inr icon"
                                                              aria-hidden="true"></i></span><span class="">0</span></div>
                                              </div>
                                          </div>
                                          <div class="fare-dtl-row total">
                                              <div class="left-wing">You Pay</div>
                                              <div class="right-wing">
                                                  <div class="c-price-display u-text-ellipsis " title=""><span
                                                          class="icon"><i class="ixi-icon-inr icon"
                                                              aria-hidden="true"></i></span><span class="">Ksh {{bookingdetails.totalfare}}</span>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="disclaimer">Please Note: Once seats are selected
                                      and paid for, they cannot be changed. Tickets are non-refundable.</div>
                                    </div>

                          <div class="cnfrm-cntnr">
                            <p class="invalid-select">{{errorMessage}}</p>
                              <div class="btn-cntnr right"><button form="ngForm"  [ngClass]="( paymentForm.valid) ? 'enabled':'disabled'"  [disabled]="paymentForm.invalid"  class="c-btn u-link centered-img">
                                      <div class="u-ripple"><span class="u-ripple-circle"></span></div>
                                      <div class="centered-img">
                                        <img *ngIf="!loading"  src="/assets/img/company1/mpesa-logo.png" />
                                        <span>   <b *ngIf="loading">Processing.....</b>  <b *ngIf="!loading">PAY WITH MPESA</b></span>
                                      </div>
                                  </button></div>
                          </div>
                      </div>
                  </div>
          </div>
      </div>
    </div>
<app-footer></app-footer>
