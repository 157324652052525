<div class="header-wrapper row">
    <div class="header-top theme-bg">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <div class="header-top-left text-md-left">
                <ul>
                    <li><a href="tel:+254701205076"><i class="icofont-ui-call"></i> +254 701 205 076</a></li>
                    <li><a href="mailto:sooresinvestmentltd@gmail.com"><i class="icofont-email"></i>sooresinvestmentltd@gmail.com</a></li>
                    
                  </ul>
            
            </div>
          </div>
          <div class="col-md-4">
              <div class="header-top-right d-none d-md-block">
                  <ul>
                      <li><a href="https://www.facebook.com/p/Soores-Luxury-Coaches-100083557411358/" target="_blank"><i class="icofont-facebook"></i></a></li>

                      <li style="margin-left:2px;"><a href="https://twitter.com/SooresLC"  target="_blank"> <i class="icofont-twitter"></i></a></li>
                      <li style="margin-left:2px;"><a href="https://www.instagram.com/soores_luxury_coach"  target="_blank"> <i class="icofont-instagram"></i></a></li>

                    </ul>
              </div>
          </div>
        </div>
      </div>
    </div>

    <div class="navbar-area"> 
    </div>
    
    <nav class="navbar navbar-default navbar-inverse navbar-theme navbar-theme-abs navbar-theme-transparent navbar-theme-border" id="main-nav">
      <div class="container">
          <div class="navbar-inner nav">
            <div class="navbar-header">
              <button class="navbar-toggle collapsed" data-target="#navbar-main" data-toggle="collapse" type="button" area-expanded="false">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
              <a class="navbar-brand" href="/">
                <img src="assets/img/logo/logo-small.png" height="110" alt="Soores Ltd"  alt="Image Alternative text" title="Image Title"/>
                
              </a>
            </div>
            <div class="collapse navbar-collapse" id="navbar-main">
            
              <ul class="nav navbar-nav pd-nav desktop-menu"> 
                <li>
                  <a routerLink="/" role="button" >
                    <b>Home</b>
                  </a>
              </li>

                <li>
                  <a routerLink="/about-us" role="button" >
                    <b>About Us</b>
                  </a>
              </li>
              <li>
                <a routerLink="/contact-us" role="button" >
                  <b>Contacts</b>
                </a>
            </li>
            
              </ul>
            </div>
          </div>
        </div>
    </nav>
</div>
