import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry } from 'rxjs/operators'
import  { environment } from '../../environments/environment'
const httpOptions = {
  headers: new HttpHeaders({
   'Authorization':'Token ' + environment.api_key
  })
};
@Injectable({
  providedIn: 'root'
})
export class ConfirmService {

  constructor(
    private http:HttpClient
  ) { }


  confirmMpesa(booking_id:any){

    return this.http.get(environment.confirmApi + '?booking_id='+booking_id,httpOptions)
  }

}
