
<div class="theme-footer" id="mainFooter">
  <div class="container _ph-mob-0">
    <div class="row row-eq-height row-mob-full" data-gutter="60">

      <div class="col-md-3 col-lg-3 col-sm-12 mobile-hidden">
        <div class="theme-footer-section theme-footer-">
          <a class="theme-footer-brand _mt-20" href="#">
            <img src="assets/img/company1/logo.png" alt="http://soores.com/" title="Image Title"/>
          </a>
        </div>
      </div>

      <div class="col-md-3 col-lg-3 col-sm-12">
        <div class="theme-footer-section theme-footer-">
          <h5 class="theme-footer-section-title">Our Contacts</h5>
          <ul class="theme-footer-section-list">
            <li>
              <h6 class="phone-titles" style="margin-top:0px;"> Nairobi:</h6>
              <span class="phones-wrapper"><a class="contacts-links" href="tel:+254701205076">+254 701 205 076</a> </span>
            </li>
            <li>
              <h6 class="phone-titles"> Moyale :</h6>
              <span class="phones-wrapper"> <a class="contacts-links" href="tel:+254701199253">+254 701 199 253</a> </span>
            </li>
            
            <li>
              <h6 class="phone-titles"> Email:</h6>
              <span class="phones-wrapper"><a href="mailto:sooresinvestmentltd@gmail.com">sooresinvestmentltd@gmail.com</a></span>
            </li>

          </ul>
        </div>

      </div >

      <div class="col-md-3 col-lg-3 col-sm-12 mobile-hidden">
        <div class="theme-footer-section theme-footer-">
          <h5 class="theme-footer-section-title">Top Routes</h5>
          <ul class="theme-footer-section-list">
            <li>
              <a (click)="clickNairobiMy()" >Nairobi - Moyale</a>
            </li>
            <li>
              <a (click)="clickNairobiMr()" >Nairobi - Marsabit</a>
            </li>
            <li>
              <a (click)="clickNairobiIs()" >Nairobi - Isiolo </a>
            </li>
            <li>
              <a (click)="clickNairobiNy()" >Nairobi - Nanyuki </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-md-3 col-lg-3 col-sm-12 mobile-app-cta">
        <div class="_mt-40 mobile-app-cta-inner text-center">
            <h3 class="_ml-10 ">GET THE APP!</h3>
            <a class="centered-img" href="https://play.google.com/store/apps/details?id=io.andronicus.buupass&hl=en&gl=US">
              <img src="assets/img/company1/app-logo-GooglePlay.png" alt="Download on play store" class="icon-app-store">
          </a>
        </div>
      </div>

    </div>
  </div>
</div>
<div class="theme-copyright">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <p class="theme-copyright-text">Copyright &copy; 2021
          <a href="https://buupass.com/">BuuPass Kenya Limited</a>. All rights reserved.
        </p>
      </div>
      <div class="col-md-6">
        <ul class="theme-copyright-social">
          <li>
            <a class="fa fa-facebook"  href="https://www.facebook.com/p/Soores-Luxury-Coaches-100083557411358/" target="_blank"></a>
          </li>

          <li>
            <a class="fa fa-twitter" href="https://twitter.com/sooreslc?lang=en" target="_blank"></a>
          </li>
          <li>
            <a class="fa fa-tiktok" href="https://www.tiktok.com/@sooresluxurycoarch/" target="_blank">
              <svg fill="#ffffff" width="15px" height="15px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xml:space="preserve"><path d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z"/></svg>
            </a>
          </li>

          <li>
            <a class="fa fa-instagram" href="https://www.instagram.com/soores_luxury_coach" target="_blank"></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
