<app-headermain></app-headermain>
<div class="progress-cntr-wrpr">
        <div class="progress-cntr">
            <div class="progress-tracker-cntr transparent">
                <div class="progress-tracker">
                    <div class="progress-item passed ">
                        <div class="item-wrapper">
                            <div class="progess-item-label">Booking  </div>
                        </div>
                    </div>
                    <div class="progress-item passed">
                        <div class="item-wrapper">
                            <div class="progess-item-label">Payment</div>
                        </div>
                    </div>
                    <div class="progress-item last current">
                        <div class="item-wrapper">
                            <div class="progess-item-label">Ticket</div>
                        </div>
                    </div>
                    <div class="progress-tracker-completed" style="width: 0%;"></div>
                </div>
            </div>
        </div>
    </div>
<div class="theme-page-section  theme-page-section-gray">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-md-offset-4">
              <div class="theme-payment-success">
                <div class="theme-payment-success-header">
                  <i class="fa fa-check-circle theme-payment-success-header-icon"></i>
                  <h1 class="theme-payment-success-title">Booking Successful</h1>
                  <p class="theme-payment-success-subtitle">Thank You. Your Booking Order is Confirmed Now.
                        A confirmation SMS has been sent to your provided phone number.</p>
                </div>
                <div class="theme-payment-success-box">
                  <ul class="theme-payment-success-summary">
                    <li>BookingID
                      <span>{{ticketDetails.booking_id}}</span>
                    </li>
                    <li>Travel Date
                      <span>{{ticketDetails.travel_date}}</span>
                    </li>
                    <li>Pickup
                      <span>{{ticketDetails.pickup_location}}</span>
                    </li>
                    <li>Dropoff
                      <span>{{ticketDetails.drop_off_location}}</span>
                    </li>
                    <li>Seats
                      <span>{{ticketDetails.booked_seats}}</span>
                    </li>
                    <li >Passenger

                          <span >
                              <div *ngFor="let passenger of ticketDetails.passengers">
                            {{passenger.name}} seat <b>{{passenger.seat_number}}</b>
                              </div>
                          </span>


                      </li>
                  </ul>
                  <p class="theme-payment-success-check-order">A confirmation Email and SMS has been sent to your provided email address and phone.

                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>


<app-footer></app-footer>
