import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {
  sub:any;
  ticketDetails:any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      let data= params['id'];
      let decodec:any=JSON.parse(atob(data));
      this.ticketDetails=decodec;
      //let decoded=JSON.parse(atob(data));
      console.log(decodec);

    });
  }

}
