// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// const homeUrl= 'http://staging.api.marketplace.buupass.com/';
// export const environment = {
//   production: false,
//   getDestinations: homeUrl+'cities?format=indexed',
//   searchBuses: homeUrl+'buses',
//   bookingApi: homeUrl+'website/api_booking',
//   bookingApiV2: homeUrl+'website/api_booking_v2',
//   confirmApi:homeUrl+'website/api_mpesa_query_status',
// };

const homeUrl= 'https://soores.buupass.com/api/v1/';
  const v2P='https://pay-v2.buupass.com/payments/';
export const environment = {
    api_key:'329ce1fc60d44f9d350016df8167c65abbd73433',
    production: false,
    getDestinations: homeUrl+'website/api_destinations/',
    searchBuses: homeUrl+'website/api_search',
    bookingApi: homeUrl+'website/api_booking/',
    bookingApiV2: homeUrl+'website/api_booking_v2/',
    confirmApi:homeUrl+'website/api_mpesa_query_status/',
    v2confirm: v2P+'c2b/transaction/status/',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
