<app-headerhome class="header-sec"></app-headerhome>
<div class="padding-top padding-bottom-mini">
    <!-- Container Area - Boxes Services -->
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h3>Contact Form</h3>
                <form id="form-contact" class="form-theme" action="#">
                    <input type="text" placeholder="Name" name="Name" required="">
                    <input type="email" placeholder="Email" name="Email" required="">
                    <input type="number" placeholder="Phone" name="Phone" required="">
                    <textarea placeholder="Your Message" name="message" required=""></textarea>
                    <input type="submit" name="Submit" value="Send Message" class="btn btn-primary">
                </form> 
                <div id="result"></div>  
            </div>

            <!-- Sidebars -->
            <div class="col-md-4">
                <!-- contact-list-->
                <div class="contact-list-container">
                <ul class="contact-list ">
                <li>
                    <h4>
                        <i class="icofont-email round-icon"></i> <span class="contact-header"> Email:</span></h4>
                        <p><a href="mailto:sooresinvestmentltd@gmail.com" class="contact-link">sooresinvestmentltd@gmail.com</a></p>
                </li>
            
                <li>
                    <h4>
                    <i class="icofont-ui-call round-icon"></i> <span class="contact-header"> Phone Numbers:</span></h4>
                    <ul>
                      <li class="phone-line" style="padding-bottom:2px;">
                        <h6 class="phone-titles" style="margin-top:0px;"> Nairobi:</h6>
                        <span class="phones-wrapper"><a class="contacts-links" href="tel:+254701205076">+254 701 205 076</a>  </span>
                      </li>
                      <li style="padding-bottom:2px;">
                        <h6 class="phone-titles"> Moyale </h6>
                        <span class="phones-wrapper"><a class="contacts-links" href="tel:+254701199253">+254 701 199 253</a>  </span>
                      </li>
                      <li style="padding-bottom:2px;">
                        <h6 class="phone-titles"> Feedback:</h6>
                        <span class="phones-wrapper"><a class="contacts-links" href="tel:+254701205076">+254 701 205 076</a></span>
                      </li>
                    </ul>
                </li>
            
                <li>
                    <h4 class="contact-header">
                        <i class="fa fa-life-ring round-icon"></i><span class="contact-header"> Office:</span></h4>
                    <p>SOORES INVESTMENT LIMITED</p>
                    <p>P.O. Box 76429-00508 Nairobi, Kenya</p>
                      
  
                     
                  
                </li>
            </ul>
                </div>
                <!-- End contact-list-->

               
            </div>
            <!-- End Sidebars -->
        </div>
    </div>
    <!-- End Container Area - Boxes Services -->
</div>
<app-footer></app-footer>