import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse, HttpHeaders  } from '@angular/common/http';
import { retry } from 'rxjs/operators'
import  { environment } from '../../environments/environment'
const httpOptions = {
  headers: new HttpHeaders({
   'Authorization':'Token ' + environment.api_key
  })
};
@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(
    private http:HttpClient
  ) { }

  onSearch(search : any) {
    return this.http.get(environment.searchBuses + '?start_point='+search.pickup+'&end_point='+search.dropoff+'&date='+search.traveldate,httpOptions)
    .pipe(
      retry(3),
    );;

  }
}
