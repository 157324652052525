import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService, DataService } from '../services/index'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {
  private sub: any;
  bookingdetails:any;
  returndetails:any;
  bookingdata:any={};
  passengers=[];
  fsname:any;
  lsname:any;
  isdno:any;
  residence:any;
  phoneno:any;
  returnpassengers=[];
  formValidation=[];
  isError:boolean=false;
  errorMessage:any;
  res:any;
  is_return:false;
  loading=false;
  paymentForm: UntypedFormGroup;
  submitted = false;
  formDataObj={}
  copyMessage='Copy traveller 1 details';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service:PaymentService,
    private dataService:DataService,
    private locationStrategy: LocationStrategy,
    private _fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.preventBackButton();
    this.dataService.currentMessage.subscribe(message =>{

      if(!message){
        message = localStorage.getItem('payments-periska')
        if(!message) {
         this.router.navigate(['/'])
       }
      }else{
        localStorage.setItem('payments-periska', message)
      }

      let encoded =JSON.parse(atob(message))
      this.bookingdetails = JSON.parse(atob(encoded.travel));
      if(encoded.returning){
        this.returndetails = JSON.parse(atob(encoded.return));
        this.bookingdetails.totalfare=this.bookingdetails.totalfare + this.returndetails.totalfare;
      }

      this.is_return=encoded.returning;
      this.bookingdetails.seatsSelected.forEach((seat) => {
        let fsname='fname'+seat;
        let lsname='lname'+seat;
        let isdno='idno'+seat;
        let phoneno='phoneno'+seat;
        let residence='residence'+seat;
        this.formDataObj[fsname]=['', [Validators.required,Validators.pattern('^[a-zA-Z ]+$'),Validators.minLength(3)]];
        this.formDataObj[lsname]=['', [Validators.required,Validators.pattern('^[a-zA-Z ]+$'),Validators.minLength(3)]];
        this.formDataObj[phoneno]=['', [Validators.required, Validators.minLength(10),Validators.pattern('^(?:254|\\+254|0)(7|1)[0-9]{8}$')]];
        this.formDataObj[residence]=['',Validators.required];
        this.formDataObj[isdno]=['', [Validators.required, Validators.minLength(7)]];
      });
    });
    // this.formDataObj['email']=['', Validators.required];
    this.formDataObj['mobileNumber']=['', [Validators.required, Validators.minLength(10),Validators.pattern('^(?:254|\\+254|0)(7|1)[0-9]{8}$')]];
    this.paymentForm =this._fb.group(this.formDataObj);
  }

  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    })
  }

  get f() { return this.paymentForm.controls; }

copyDetails(){
  let seatno=0;
  let fsname: string, lsname: string, isdno: string, phoneno: string, residence:string;
  let passenger
    this.bookingdetails.seatsSelected.forEach((seat) => {
      seatno++;
      let passenger={};
      this.fsname='fname'+seat;
      this.lsname='lname'+seat;
      this.isdno='idno'+seat;
      this.phoneno = 'phoneno'+seat;
      this.residence='residence'+seat;
      if( seatno == 1){
        fsname=this.paymentForm.controls[this.fsname].value
        lsname=this.paymentForm.controls[this.lsname].value
        isdno=this.paymentForm.controls[this.isdno].value
        phoneno=this.paymentForm.controls[this.phoneno].value
        residence=this.paymentForm.controls[this.residence].value
      }

      if (seatno > 1){
        this.paymentForm.get(this.fsname).setValue(fsname);
        this.paymentForm.get(this.lsname).setValue(lsname);
        this.paymentForm.get(this.isdno).setValue(isdno);
        this.paymentForm.get(this.phoneno).setValue(phoneno);
        this.paymentForm.get(this.residence).setValue(residence);
      }
    });
    this.copyMessage ='Copied!'
    setTimeout(() => {
      this.copyMessage ='Copy traveller 1 details'
    }, 2000);

}

  submitForm(){
    if (this.paymentForm.valid) {
    let value = this.paymentForm.value;
    this.isError=false;
    this.loading=true;
    this.passengers=[];

    this.bookingdetails.seatsSelected.forEach((seat) => {
      let passenger={};
      this.fsname='fname'+seat;
      this.lsname='lname'+seat;
      this.isdno='idno'+seat;
      this.phoneno = 'phoneno'+seat;
      this.residence='residence'+seat;

  let seatprice=(parseInt(this.bookingdetails.totalfare)/this.bookingdetails.seatsSelected.length)
  passenger={
          "customer_type": "normal",
          "first_name": value[this.fsname],
          "last_name": value[this.lsname],
          "phone_number":"0"+value[this.phoneno].substr(value.mobileNumber.length - 9),
          "id_number": value[this.isdno],
          "residence": value[this.residence],
          "luggage": "No",
            "seat": {
              "id": seat,
              "price": seatprice
            }
  }

  this.passengers.push(passenger);

});



if(this.is_return){

  this.returnpassengers=[];
  this.returndetails.seatsSelected.forEach((seat) => {
    let passenger={};
    let seatprice=(parseInt(this.returndetails.totalfare)/this.returndetails.seatsSelected.length)

    passenger={
            "customer_type": "",
            "first_name": value[this.fsname],
            "last_name": value[this.lsname],
            "phone_number":"0"+value[this.phoneno].substr(value.mobileNumber.length - 9),
            "id_number": value[this.isdno],
            "luggage": "No",
              "seat": {
                "id": seat,
                "price": seatprice
              }
    }

this.returnpassengers.push(passenger);

});

  this.bookingdata ={
       "booking":[
          {
             "booking_agent_id":"",
             "booking_organization_id":"buupass",
             "adult_no":1,
             "booked_by":"",
             "booking_channel":"soores_website",
             "api_client":"soores_website",
             "child_no":0,
             "discount":"0",
            "fleet_registration_id": this.bookingdetails.fleet_registration_id,
            "fleet_type_id": this.bookingdetails.fleet_type_id,
             "offer_code":"",
             "passengers":this.passengers,
             "payment_type":"MPesa",
            "payee_phone_number":"0"+value.mobileNumber.substr(value.mobileNumber.length - 9),
            "pickup_location": this.bookingdetails.pickup,
            "drop_location": this.bookingdetails.dropoff,
            "trip_id": this.bookingdetails.trip_id,
            "travel_date": this.bookingdetails.search_details.traveldate,
            "route_id": this.bookingdetails.route_id,
             "special_no":"0",
            "number_of_passengers":this.bookingdetails.seatsSelected.length,
             "payment_channel":"Mpesa"
          }
       ]
    }


  this.service.submitBookingReturn(this.bookingdata).subscribe(
    data  => {
      this.loading=false;
      this.res=data;
      if(this.res.success){
        let f=btoa(JSON.stringify(this.res.data))
        this.router.navigate(['/confirm-payments', f])
      }else{
          this.isError=true;
          this.errorMessage=this.res.message;
      }
    },
    error  => {
      this.loading=false;
      this.isError=true;
      this.errorMessage="Sorry there has been a technical problem.Please try again ";
    }
    );
}else{
  this.bookingdata ={
       "booking":[
          {
             "booking_agent_id":"",
             "booking_organization_id":"buupass",
             "adult_no":1,
             "booked_by":"",
             "booking_channel":"soores_website",
             "api_client":"soores_website",
             "child_no":0,
             "discount":"0",
             "fleet_registration_id": this.bookingdetails.fleet_registration_id,
             "fleet_type_id": this.bookingdetails.fleet_type_id,
             "offer_code":"",
             "passengers":this.passengers,
             "payment_type":"MPesa",
            "payee_phone_number":"0"+value.mobileNumber.substr(value.mobileNumber.length - 9),
            "pickup_location": this.bookingdetails.pickup,
            "drop_location": this.bookingdetails.dropoff,
            "trip_id": this.bookingdetails.trip_id,
            "travel_date": this.bookingdetails.search_details.traveldate,
            "route_id": this.bookingdetails.route_id,
             "special_no":"0",
            "number_of_passengers":this.bookingdetails.seatsSelected.length,
             "payment_channel":"Mpesa"
          }
       ]
    }

  this.service.submitBooking(this.bookingdata).subscribe(
    data  => {
      this.loading=false;
      this.res=data;
      if(this.res.success){
        let f=btoa(JSON.stringify(this.res.data))
        this.router.navigate(['/confirm-payments', f])
      }else{
          this.isError=true;
          this.errorMessage=this.res.message;
      }

    },
    error  => {
      this.loading=false;
      this.isError=true;
      this.errorMessage="Sorry there has been a technical problem.Please try again ";

    }

    );

}



  }
}

}
